// export default Resume;
import React, { useEffect } from "react";
import { DocumentTextIcon } from "@heroicons/react/solid";
import particlesJS from "particles.js";
import particlesConfig from "../particles-config.json";

const Resume = () => {


  const styles = {
    madeWithLove: {
      marginTop: "20px",
      fontSize: "1rem",
      color: "gray",
      fontStyle: "italic",
      textAlign: "center",
    },
    boldText: {
      fontWeight: "bold",
    },
    iframe: {
      height: "85vh",
      width: "700px",
    },
  };

  return (
    <div className="center-content">
      <div className="content-wrapper relative z-1">
        <div className="text-center mb-4">
          <br />

        </div>
        <div className="center-iframe">
          <iframe
            src="https://drive.google.com/file/d/1gUZO2f7hONhFF3E0sjnC56y2fRn-FMKB/preview"
            
            style={styles.iframe}
            allow="autoplay"
          ></iframe>
        </div>
        <div style={styles.madeWithLove}>
          Made with <span role="img" aria-label="heart">❤️</span> by{" "}
          <span style={styles.boldText}>Anastasiya </span> | December 2024
        </div>
      </div>
    </div>
  );
};

export default Resume;
