import React from "react";

export default function About() {
  return (
    <section
    id="about"
    className="relative pt-20 bg-gradient-to-b from-gray-100 via-gray-50 via-purple-50 to-purple-100"
      >
      <div className="content-wrapper relative z-1">
        <div className="container mx-auto flex flex-col md:flex-row items-center px-10 py-20">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 flex justify-center md:justify-start">
            <div
              className="rounded-full overflow-hidden"
              style={{ width: "65%", height: "65%" }}
            >
              <img
                className="object-cover object-center w-full h-full"
                alt="hero"
                src="./anastasiya.png"
              />
            </div>
          </div>
          <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-black">
              Hi, I'm Anastasiya!
              <br className="hidden lg:inline-block" />
            </h1>
            <p className="mb-8 leading-relaxed">
              I am a senior Computer Science student at the Georgia Institute of Technology, specializing in Intelligence and Information Networks.
              I am passionate about leveraging technology and innovation to create positive change in the community.
            </p>
            <div className="flex flex-col md:flex-row justify-center">
              <a
                href="#contact"
                className="inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded mb-4 md:mb-0 text-lg"
              >
                Connect With Me
              </a>
              <a
                href="#work"
                className="ml-0 md:ml-4 inline-flex text-white bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
              >
                See My Experience
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}
