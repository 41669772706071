import React from "react";
import { experiences } from "../data";
import "../index.css";
import { BriefcaseIcon } from "@heroicons/react/solid";
export default function Work() {
  return (
    <section id="work" >
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
        <BriefcaseIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-black mb-4">
            Work Experience
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Leveraging my industry experience, I translate knowledge into impactful contributions. Discover my latest work and internship experiences below.
          </p>
        </div>
        <div className="relative pl-4 sm:pl-20">
        <div className="absolute left-1/6 sm:left-2/5 transform -translate-x-1/2 h-full border-l-4 border-gray-300"></div>
          <div className="flex flex-col space-y-8">
            {experiences.map((experience, index) => (
              <div
                key={index}
                className={`relative flex items-center`}
                style={{ marginLeft: '5%' }}
              >
                <div className="absolute left-0 transform -translate-x-1/2 flex items-center">
                  <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                  <div className="w-8 h-0.5 bg-gray-300 "></div>
                </div>
                <div className="bg-white p-2 rounded-md shadow-md ml-10 w-[85%] experience-card" >
                  <div className="flex items-center mb-2">
                  <a href={experience.companyLink} target="_blank" rel="noreferrer">
                  <img
                    src={experience.logoUrl}
                    alt={`Logo for ${experience.company}`}
                    className="w-14 h-14 rounded-full mr-3"
                    style={{ minWidth: '56px', minHeight: '56px', maxWidth: '100%' }}
                  />
                  </a>
                    <div>
                      <h1 className="text-gray-900 text-lg title-font font-medium mb-1">
                        {experience.title}
                      </h1>
                      <p className="leading-relaxed text-sm text-gray-500">{experience.duration}</p>
                      <p className="leading-relaxed text-base">{experience.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}