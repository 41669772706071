import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
import { projects } from "../data";

export default function Projects() {
  const groupedProjects = projects.reduce((acc, project) => {
    if (!acc[project.category]) {
      acc[project.category] = [];
    }
    acc[project.category].push(project);
    return acc;
  }, {});

  return (
    <section id="projects" className=" text-black-400  body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-black">
            Projects
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-black">
            I enjoy learning outside the classroom and applying my skills in practice. These are my favorite projects I have built in the recent past.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
    {projects.slice(0, 4).map((project) => (
      <div
        key={project.image}
        className="relative rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
      >
        <a href={project.link} target="_blank" rel="noreferrer">
          <img
            alt={project.title}
            src={project.image}
            className="w-full h-[280px] object-cover transition-transform duration-300 hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-800 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 p-6 flex flex-col justify-end">
            <h2 className="text-green-400 text-sm font-medium mb-1">
              {project.subtitle}
            </h2>
            <h1 className="text-white text-lg font-semibold mb-3">
              {project.title}
            </h1>
            <p className="text-gray-300 text-sm leading-relaxed">
              {project.description}
            </p>
          </div>
        </a>
      </div>
    ))}
  </div>
  </div>
    </section>
  );
}
