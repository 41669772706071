import React, { useState } from "react";
import { teachingExperiences, researchPapers } from "../data";
import "../index.css";
import { AcademicCapIcon, BookOpenIcon } from "@heroicons/react/solid";

export default function TeachingAndResearch() {
  const [activeTab, setActiveTab] = useState("teaching");

  return (
    <section id="teaching-research">
      <div className="container px-5 py-10 mx-auto">
        
        <div className="text-center mb-10">
        <AcademicCapIcon className="mx-auto inline-block w-10 mb-4"/>
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-black mb-4">
            Teaching & Research
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Combining my passion for teaching and research, I've worked on impactful projects and mentored students to explore computer science.
          </p>
        </div>

        {/* Tabs */}
        <div className="flex justify-center space-x-6 mb-10">
          <button
            className={`px-6 py-2 rounded-lg text-lg font-medium ${
              activeTab === "teaching" ? "bg-purple-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setActiveTab("teaching")}
          >
            
            Teaching Experience
          </button>
          <button
            className={`px-6 py-2 rounded-lg text-lg font-medium ${
              activeTab === "research" ? "bg-purple-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setActiveTab("research")}
          >
            
            Research Papers
          </button>
        </div>

        {/* Content */}
        <div className="relative mx-auto max-w-[85%]">
  <div className="flex flex-col space-y-8">
    {activeTab === "teaching" &&
      teachingExperiences.map((experience, index) => (
        <div key={index} className="relative flex items-center">
          <div className="bg-white rounded-md w-full">
            <div className="flex justify-between items-center mb-2">
              <img
                src={experience.logoUrl}
                alt={`Logo for ${experience.course}`}
                className="w-14 h-14 rounded-full mr-3"
                style={{ minWidth: "56px", minHeight: "56px", maxWidth: "100%" }}
              />
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  {/* Title */}
                  <h1 className="text-gray-900 text-lg title-font font-medium mb-1">
                    {experience.role}
                  </h1>
                  {/* Date */}
                  <p className="text-sm text-gray-500">{experience.duration}</p>
                </div>
                {/* Description */}
                <p className="leading-relaxed text-base">{experience.description}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

    {activeTab === "research" &&
      researchPapers.map((paper, index) => (
        <div key={index} className="relative flex items-center">
          <div className="bg-white rounded-md w-full">
            <div className="mb-2 flex justify-between items-center">
              <h1 className="text-gray-900 text-lg title-font font-medium">
                {paper.title}
              </h1>
              <p className="text-sm text-gray-500">{paper.date}</p>
            </div>
            <p className="leading-relaxed text-sm text-gray-700 italic">
              Authors: {paper.authors}
            </p>
            {paper.link ? (
              <a
                href={paper.link}
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 underline mt-2 block"
              >
                Read Full Article
              </a>
            ) : (
              <p className="text-gray-500 mt-2">{paper.status}</p>
            )}
          </div>
        </div>
      ))}
      </div>
    </div>
      </div>
    </section>
  );
}
