export const projects = [
  {
    title: "SageWell",
    subtitle: "Stanford TreeHacks Hackathon",
    description:
      "SageWell is an application intended to help the elderly to find answers to any health-related questions using their voice and power of LLMs.",
    image: "./sagewell.gif",
    link: "https://github.com/PrabhleenKaurLamba/SageWell",
    category: "Machine Learning Applications"
  },
  {
    title: "Mood Tunes",
    subtitle: "Georgia Tech HackGT Hackathon",
    description:
      "MoodTunes is a tool that is capable of recognizing a person's mood by photo and suggesting Top 5 Spotify songs based on the mood.",
    image: "./mood_tunes.gif",
    link: "https://github.com/adriencao/moodtunes",
    category: "Machine Learning Applications"
  },
  {
    title: "Crossy Road",
    subtitle: "Georgia Institute of Technology",
    description:
      "An Android application that has the functionality of a famous Crossy Road game. In the game, you need to reach the end by overcoming obstacles.",
    image: "./cross_road.gif",
    link: "https://github.com/anastasiya-masalava/Crossy_Road.git",
    category: "Game Applications"
  },
  {
    title: "Wordle Game",
    subtitle: "Georgia Institute of Technology",
    description:
      "A JavaFX application that has the functionality of a famous Wordle game. The game asks a player to guess a word in 6 attempts and provides hints from failed attempts.",
    image: "./Jordle.gif",
    link: "https://github.com/anastasiya-masalava/wordle.git",
    category: "Game Applications"
  },
];

export const testimonials = [
  {
    quote:
      "Anastasiya has worked as an intern at Steelcase for the summer of 2022 on my team, providing a high level of support and quick learning to a very complex area of our company. She provided creative solutions that were able to leverage new technology and integrate into our systems. Her attitude and likeable personality have been refreshing and provided a significant boost to our productivity this summer. I'd highly recommend her.",
    image: "/steve_silvey.jpeg",
    name: "Steve Silvey",
    company: "Steelcase",
  },
  {
    quote:
      "I have had the pleasure to intern with Anastasiya over the course of this summer during our time together at Steelcase, and she has been nothing short of a great person, great colleague, and great friend. Anastasiya has a technological wit and desire to learn more. She always challenges the status quo and is never afraid to ask questions or try new things. She was inserted into our team and given projects and challenges she hadn't been exposed to before, and became an expert.",
    image: "/josh.png",
    name: "Joshua Mohanroy",
    company: "Steelcase",
  }
];

export const experiences = [
  {
    title: "Software Engineer Intern",
    companyLink: "https://www.amazon.com",
    logoUrl: "/amazon.png",
    company: <strong>Amazon</strong>,
    duration: "August 2024 - November 2024",
    description: "Developed a report generation algorithm using Anthropic LLMs and K-means clustering to summarize consumer feedback and optimized it with parallel computing.  ",
  },
  {
    title: "Machine Learning Engineer Intern",
    companyLink: "https://doordash.engineering/",
    logoUrl: "/doordash_logo.png",
    company: <strong>Doordash</strong>,
    duration: "May 2024 - August 2024",
    description: "Developed and implemented a Vision Transformer algorithm to detect fraudulent behavior by identifying similarities between customers refund images. ",
  },
  {
    title: "Software Engineer Intern",
    companyLink: "https://www.amazon.com",
    logoUrl: "/amazon.png",
    company: <strong>Amazon</strong>,
    duration: "May 2023 - July 2023",
    description: "Developed an Image Classification model utilizing AutoGluon technology and achieved a 93% accuracy by hyperparameter tuning and data augmentation. ",
  },
  {
    title: "Undergraduate Researcher | Machine Learning",
    companyLink: "https://www.gatech.edu",
    logoUrl: "/georgia_tech.png",
    company: <strong>Georgia Institute of Technology</strong>,
    duration: "January 2024 - Present",
    description: "Develop and assess a multi-modal model incorporating text CNN and InceptionV3 models, integrating feature fusion, to enhance natural disaster detection.",
  },

  {
    title: "Senior Teaching Assistant | Computer Organization and Programming",
    companyLink: "https://www.gatech.edu",
    logoUrl: "/georgia_tech.png",
    company: <strong>Georgia Institute of Technology</strong>,
    duration: "January 2023 - May 2024",
    description: "Supervise a team of 6 Teaching Assistants, create course content, and teach labs for 50 students on the topics such as computer logic and layout, Assembly and C language.",
  },
];
export const podcastEpisode = {
  id: 1,
  title: "My interview for the Steelcase Intern Podcast",
  description: "In this episode, we introduce the podcast and discuss...",
  embedCode:
    '<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/60CkGWu1xqBi76wjN6lige?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>',
};

export const skills = [
  "Java",
  "Python",
  "C/C++",
  "Assembly",
  "JavaScript",
  "CSS/HTML",
  "TypeScript",
  "SQL",
  "AWS",
  "Git"
];

export const researchPapers = [
  {
    title: "Towards Enhancing the Utility of Social Media Images in Earthquake Disaster Assessment",
    date: "June 2024",
    authors: "Huili Huang, Danrong Zhang, Anastasiya Masalava, M. Mahdi Roozbahani, Nimisha Roy, J. David Frost",
    status: "Earthquake Spectra, under review",
  },
  {
    title: "Improving Consumer Feedback Analysis Using Large Language Models",
    date: "November 2024",
    authors: "Anastasiya Masalava, Manish Kumar Gupta",
    status: "Amazon Science, under review",
  },
  {
    title: "Applying Vision Transformers to Detect and Prevent Photo Recycling Fraud",
    date: "August 2024",
    authors: "Anastasiya Masalava",
    status: "Published",
    link: "https://careersatdoordash.com/blog/part-1-doordash-2024-summer-intern-projects/",
  },
];

export const teachingExperiences = [
  {
  role: "CS 2110, Computer Organization and Programming",
  course: "Computer Organization and Programming",
  duration: "January 2023 - May 2024",
  description: "An introduction to computer hardware, machine language, assembly language, and C programming.",
  logoUrl: "/georgia_tech.png", 
  },
  {
    role: "CS 1301, Introduction to Python",
    course: "Intro to Python",
    duration: "January 2022 - December 2022",
    description: "An introduction to computing principles and programming practices with an emphasis on the design and implementation of problem solutions.",
    logoUrl: "/georgia_tech.png", 
  }
  
];
